body {
  font-family: 'Lato', 'Arial', sans-serif;
}

.fa-btn {
  margin-right: 6px;
}

.dashboard {
  .separator-sm-filter {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .mtop25 {
    margin-top: 25px;
  }

  .message-info {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .text-right {
    text-align: right;
  }
}

.form-invite{
  .price {
    margin: 15px 0;
  }
}

.email-group-addon {
  background: transparent;
  padding: 0;
  border: none;
}

.no-bradius {
  border-radius: 0;
}

.coupon-modal {
  .modal-footer {
    text-align: left;

    .book-coupon {
      display: block;
      float: left;
    }

    .cancel-coupon {
      display: block;
      float: left;
      margin-left: 15px;
    }

    .btn-close {
      display: block;
      float: right;
    }
  }
}